import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import NotFoundIMG from "../../assets/image.png";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import {
  Avatar,
  Header,
  Body,
  ChatBubble,
  Footer,
  NotFound,
  CardDiv,
  SectionHeader,
  DivSelect,
  DivButton,
  ContainerMessage,
  ContainerFilter,
  ContainerFooter,
} from "./styles";

import "./component/NavTab.css";

import {
  indexContactChatService,
  indexMessageChatService,
  serviceLinkGroup,
  registerObservation,
  updateUser,
  updateUserIdChat,
  countContacts,
  indexFilterMessageChatService,
  getRegisterShortcutMessages,
  listAllTransferable,
  readReceiptMessage,
  unattendedCustomers,
} from "../../services/Chat";

import Swal from "sweetalert2";

import {
  FormGroup,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Label,
  Tooltip,
  CardHeader,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  InputGroupAddon,
  Alert,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import debounce from "lodash/debounce";

import { ChatContactComponent } from "./component/ChatContactComponent";

import Select from "react-select";
import { phoneMask } from "utils/mask";
import { listAllInstanceService } from "../../services/Instance";
import { ChatNewMessageComponent } from "./component/ChatNewMessageComponent";

import parse from "html-react-parser";
import { FormatMessageWhatsApp } from "utils/FormatMessageWhatsApp";
import EventModal from "pages/calendar/Components/EventModal";

import {
  AvatarDiv,
  PreviewBody,
  ScrollShortcutMessage,
  ShortcutContainer,
  ShortcutMessageLabel,
  ShortcutTitleLabel,
} from "./component/ChatStyle";

import Switch from "react-switch";
import { getEvents } from "services/Calendar";

import RegisterGroup from "pages/client-schedule/components/RegisterGroup";
import { getAllGroups } from "services/Client-Schedule";
import {
  getObservationsById,
  getGroupsByClientId,
} from "../../services/Client-Schedule";
import { deleteObservation } from "../../services/Chat";
import { getConfig } from "services/Chat-Config";
import { FaPaperclip } from "react-icons/fa";

export const Chat = () => {
  const contactDetailsRef = useRef(null);
  const theEnd = useRef(null);
  const socket = useRef();
  const containerRef = useRef(null);
  const [tabSelected, setTabSelected] = useState(1);
  const [observation, setObservation] = useState("");
  const [isMdScreen, setIsMdScreen] = useState(window.innerWidth <= 991);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [showContact, setShowContact] = useState(true);
  const [modalNewMessage, setModalNewMessage] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [shouldUpdateInstances, setShouldUpdateInstances] = useState(true);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [signatureEnabled, setSignatureEnabled] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [instances, setInstances] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [events, setEvents] = useState([]);
  const [list, setList] = useState([]);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [showModalRegisterGroup, setShowModalRegisterGroup] = useState(false);
  const [modalSearchChat, setModalSearchChat] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [showBtnMoreContacts, setShowBtnMoreContacts] = useState(false);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState({
    client: {
      name: null,
      phone: null,
      clientPhoneNumberId: null,
      instance: null,
    },
    list: [],
  });
  const [isFilterMessage, setIsFilterMessage] = useState(false);
  const [limit, setLimit] = useState(0);
  const [previousLimit, setPreviousLimit] = useState(60);
  const [tabNavSelected, setTabNavSelected] = useState(1);
  const [numberChatService, setNumberChatService] = useState(0);
  const [filterMessage, setFilterMessage] = useState("");
  const [isAlertSearch, setIsAlertSearch] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [showSelectedContact, setShowSelectedContact] = useState(false);
  const [shortcutList, setShortcutList] = useState([]);
  const [triggerShortcut, setTriggerShortcut] = useState(false);
  const [shortcutMessage, setShortcutMessage] = useState("");
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(false);
  const [userAttendantList, setUserAttendantList] = useState([]);
  const [imagePreview, setImagePreview] = useState('');
  const [triggerPreview, setTriggerPreview] = useState(false);

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));
  const [visibleBadgeCount, setVisibleBadgeCount] = useState(0);
  const [tooltipGroupOpen, setTooltipGroupOpen] = useState([]);
  const [soundNotification, setSoundNotification] = useState(
    "https://storage.googleapis.com/simulador-fgts/audio/chat-sound-1.mp3"
  );
  const [origimButtonSelected, setOrigimButtonSelected] = useState("allConversations");
  const audioRef = useRef(new Audio("https://cdn.pixabay.com/audio/2024/10/13/audio_df01db1c23.mp3"));
  const [soundPlaying, setSoundPlaying] = useState(false);

  const [readMessages, setReadMessages] = useState("");

  const handleGetConfig = async () => {
    getConfig()
      .then(({ data }) => {
        if (data?.resultSet?.length <= 0 || !data?.resultSet[0]?.urlSound) {
          return;
        }
        setSoundNotification(data?.resultSet[0]?.urlSound);
      })
      .catch((error) => {
        console.error(error);
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const fileInputRef = useRef(null);

  const getAllUsers = async () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const { data } = await listAllTransferable();
      Swal.close();
      setUserAttendantList(data.resultSet ?? []);
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const toggleTransferConversationModal = () => {
    setSelectedOperator(false);
    setShowTransferModal(!showTransferModal);
  };

  const handleConfirmTransfer = async (clientId) => {
    if (!selectedOperator) {
      Swal.fire({
        icon: "error",
        title: "Nenhum operador selecionado",
        text: "Selecione um operador para transferir a conversa.",
      });
      return;
    }

    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    updateUserIdChat(clientId, selectedOperator)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Conversa transferida",
          text: "A conversa foi transferida com sucesso.",
        });

        setSelectedOperator(null);
        toggleTransferConversationModal();
        handleListContact({
          limit: limit,
          previousLimit: previousLimit,
        });
        handleCloseConversation();

        Swal.close();
      })
      .catch((error) => {
        console.error(error);
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response
          ? "Ocorreu um erro inesperado"
          : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const getObservations = async (clientId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const { data } = await getObservationsById(clientId);
      Swal.close();
      setList(data.resultSet ?? []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao buscar as observações. Tente novamente mais tarde.",
      });
    }
  };

  const getCountChats = async (instanceId = null, userIdChat = null, isRead = null) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const params = {};
      if (instanceId !== null) params.instanceId = instanceId;
      if (userIdChat !== null) params.userIdChat = userIdChat;
      if (isRead !== null) params.isRead = isRead;

      const { data } = await countContacts(params);
      Swal.close();
      setNumberChatService(data.resultSet ?? 0);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao buscar as observações. Tente novamente mais tarde.",
      });
    }
  };

  const handleDelete = async (obsId, clientId) => {
    const confirmed = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: `Você tem certeza que deseja deletar a Observação?`,
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (!confirmed.isConfirmed) return;

    Swal.fire({
      title: "Removendo",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = { clientId: clientId };
      await deleteObservation(obsId, data);
      Swal.close();
      getObservations(clientId);
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const handleOpenContactDetails = () => {
    setShowContactDetails(!showContactDetails);
    setEditMode(false);
    setEditedName("");
    setObservation("");
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleIndexItem = (index) => {
    setSelectedItemIndex(index);
    setShowContactDetails(false);
    setObservation("");
    if (isMdScreen) {
      setShowContact(false);
    }
  };

  const handleChat = (res) => {
    setFilter((prevFilter) => {
      if (origimButtonSelected === "allConversations") {
      handleListContact({
        filter: prevFilter,
        instanceId: selectedInstanceId,
        limit: limit,
        previousLimit: previousLimit,
      });
    }

      if (origimButtonSelected === "unread") {
        handleListContact({
          limit: limit,
          previousLimit: previousLimit,
          isRead: 0,
        });
      }
      if(origimButtonSelected === "myConversations") {
        handleListContact({
          limit: limit,
          previousLimit: previousLimit,
          userIdChat: user.userId,
        });
      }

      if (
        selectedInstance > 0 &&
        parseInt(selectedInstanceId) !== parseInt(res.instanceId)
      ) {
        return prevFilter;
      }

      if (
        Number(messages.client.clientPhoneNumberId) !==
          Number(res.clientPhoneNumberId) && 
          Number(res.userIdChat) === user.userId &&
        res.fromMe <= 0
      ) { 
          const audio = new Audio(soundNotification);
          audio.play();
      

        return prevFilter;
      
      } 
      if (
        Number(messages.client.clientPhoneNumberId) !==
          Number(res.clientPhoneNumberId) && res.userIdChat === null &&
        res.fromMe <= 0
      ) { 
        setSoundPlaying(false);
        playAlertSound();
      
        return prevFilter;
      }
    
      if (Number(res.clientPhoneNumberId) === Number(readMessages)) {
        
        handleReadMessages(res.clientPhoneNumberId, res.instanceId);
        res.isRead = 1;
        setMessages({
          ...messages,
          list: [...messages.list, res],
          
        });
      }      
      
      handleForceScrollBottom("message-chat");

      return prevFilter;

    });
  };
  
  const scrollToBottom = () => {
    theEnd.current?.scrollIntoView();
  };

  const handleForceScrollBottom = (id) => {
    var objDiv = document.getElementById(id);

    if (!objDiv) return;

    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const handleGetMessagesClient = (
    clientPhoneNumberId,
    instanceId,
    contact
  ) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    indexMessageChatService(
      clientPhoneNumberId,
      instanceId
    )
      .then(({ data }) => {
        Swal.close();
        setMessages({
          client: contact,
          list: data?.resultSet ?? [],
          groups: contact.groups ?? [],
        });
        setSelectedContact(contact);
        if (selectedContact) {
          setShowSelectedContact(true);
        }
        setShortcutMessage("");
        if (shouldUpdateInstances) {
          if (contact.instanceId && contact.instanceName) {
            setSelectedInstance({
              value: contact.instanceId,
              label: contact.instanceName,
            });
          } else {
            setSelectedInstance(null);
          }
        }

        if (isMdScreen) {
          setShowContact(false);
        }
        setShowChat(true);
        setSelectedItemIndex(contact.clientPhoneNumberId);
        handleForceScrollBottom("message-chat");
          if (origimButtonSelected === "allConversations") {
            handleListContact({
              instanceId: selectedInstanceId,
              limit: limit,
              previousLimit: previousLimit,
            });
          }
      
            if (origimButtonSelected === "unread") {
              handleListContact({
                limit: limit,
                previousLimit: previousLimit,
                isRead: 0,
              });
            }
            if(origimButtonSelected === "myConversations") {
              handleListContact({
                limit: limit,
                previousLimit: previousLimit,
                userIdChat: user.userId,
              });
            }
        setIsFilterMessage(false);
        handleShortcutList()

        setTriggerShortcut(false);

      })
      .catch((err) => {
        if (isMdScreen) {
          setShowContact(true);
          setShowChat(false);
        }

        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleReadMessages = (clientPhoneNumberId, instanceId) => {
    readReceiptMessage(clientPhoneNumberId, instanceId)
      .then(({ data }) => {})
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListContact = async (
    filter = {},
    setAtribute = true,
    callback = (res) => {
      return res;
    }
  ) => {
    try {
      const { data } = await indexContactChatService(filter);

      if (data?.resultSet) {
        const previousContactsLength = contacts.length;

        const newContacts = data.resultSet || [];
        setContacts(newContacts);
        setIsLoading(false);

        setShowBtnMoreContacts(newContacts.length >= 30);

        callback(newContacts);
      }
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleGetSearchMessagesClient = (message) => {
    if (message == "") {
      return false;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    indexFilterMessageChatService(selectedItemIndex, message)
      .then(({ data }) => {
        Swal.close();
        setMessages((prevMessages) => ({
          ...prevMessages,
          list: data?.resultSet || [],
        }));

        if (data?.resultSet.length > 0) {
          setModalSearchChat(false);
          setIsAlertSearch(false);
          setIsFilterMessage(true);
        } else {
          setIsAlertSearch(true);
          setIsFilterMessage(false);
          handleGetMessagesClient(
            selectedItemIndex,
            selectedInstanceId,
            selectedContact
          );
        }
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSendMessage = (message) => {
    let instanceLabel = selectedInstance?.label || "";
    const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));
    const messageContent = signatureEnabled
      ? `*${user.name}*\n${message.message}`
      : message.message;

    if (!instanceLabel) {
      instanceLabel = message?.instance;
    }

    if (!instanceLabel) {
      throw new Error("Instancia não selecionada");
    }

    socket.current.emit("chat-send", {
      instance: instanceLabel,
      message: messageContent,
      clientPhoneNumberId: message.clientPhoneNumberId,
      img: imagePreview
    });
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCloseConversation = () => {
    setShowSelectedContact(false);
    setShowDropdown(false);
    setSelectedItemIndex(null);
    setShowContact(true);
    setShowContactDetails(false);
    setIsFilterMessage(false);
    setTriggerShortcut(false);
    setReadMessages("")
    if (isMdScreen) {
      setShowChat(false);
    }

    setMessages({
      client: {
        name: null,
        phone: null,
        clientPhoneNumberId: null,
        instance: null,
      },
      list: [],
    });

    setShouldUpdateInstances(true);
  };

  const handleShowInstance = () => {
    listAllInstanceService()
      .then(({ data }) => {
        if (!data?.resultSet || data?.resultSet.length <= 0) return;

        const instances = data.resultSet.map((register) => ({
          value: register.id,
          label: register.name,
        }));

        setInstances(instances);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleEditClick = () => {
    setEditedName(messages.client.name || "");
    setEditMode(true);
  };

  const handleSaveName = async (clientId) => {
    const confirmResult = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja editar o nome?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (confirmResult.isConfirmed) {
      Swal.fire({
        title: "Carregando",
        text: "Por favor, aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = { name: editedName };

      try {
        const response = await updateUser(data, clientId);

        if (response) {
          Swal.fire({
            icon: "success",
            title: "Operação realizada com sucesso.",
            confirmButtonText: "Fechar",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
          });

          setMessages({
            ...messages,
            client: {
              ...messages.client,
              name: editedName,
            },
          });

          setEditMode(false);

          handleListContact({
            limit: limit,
            previousLimit: previousLimit,
          });
        }
      } catch (err) {
        const code = err.response?.data.code || "00000";
        const message =
          err.response?.data.message || "Ocorreu um erro inesperado";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      }
    }
  };

  const handleConversation = async (contact, conversation) => {
    const confirmResult = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text:
        conversation > 0
          ? "Deseja iniciar a conversa"
          : "Deseja finalizar a conversa",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (!confirmResult.isConfirmed) return;

    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await updateUserIdChat(contact.clientId).then(() => {
        setMessages({
          ...messages,
          client: {
            ...contact,
            userIdChat: user.userId,
            attendentName: user.name,
          },
        });

        if (conversation > 0) {
          setTabNavSelected(1);
        } else {
          handleCloseConversation();
        }
        handleListContact();
        getCountChats();

        Swal.close();
      });
    } catch (err) {
      const code = err.response?.data.code || "00000";
      const message =
        err.response?.data.message || "Ocorreu um erro inesperado";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const handleSaveObservation = async (clientId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const data = { text: observation };

    try {
      const response = await registerObservation(clientId, data);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Operação realizada com sucesso.",
          confirmButtonText: "Fechar",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true,
        });
        setObservation("");
        getObservations(clientId);
      }
    } catch (err) {
      const code = err.response?.data.code || "00000";
      const message =
        err.response?.data.message || "Ocorreu um erro inesperado";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const handleConfirmEdit = () => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja editar o nome?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        handleSaveName(messages.client.clientId);
      }
    });
  };

  const toggleModalGroup = async () => {
    setShowModalGroup(!showModalGroup);
    setSelectedGroup(null);
  };

  const searchModalMessage = async () => {
    setModalSearchChat(!modalSearchChat);
    setFilterMessage("");
    setIsAlertSearch(false);
  };

  const toggleModalRegisterGroup = async () => {
    setShowModalRegisterGroup(!showModalRegisterGroup);
  };

  const handleAddGroup = async (clientId, client) => {
    try {
      if (!selectedGroup) {
        Swal.fire({
          icon: "error",
          title: "Selecione...",
          text: "A seleção de um grupo é obrigatória!",
        });
        return;
      }

      Swal.fire({
        title: "Carregando",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        groups: selectedGroup,
      };

      await serviceLinkGroup(clientId, data);

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Grupo vinculado com Sucesso!",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setShowModalGroup(false);
      handleListContact({
        limit: limit,
        previousLimit: previousLimit,
      });
        setMessages((prevMessages) => ({
          ...prevMessages,
          client: client,
          groups: data?.groups?.map((group) => ({
            id: String(group.value),
            name: group.label,
          })) ?? [],
        }));
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleList = async () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await getEvents();
      const fetchedEvents = response.data.resultSet || [];

      const formattedEvents = fetchedEvents.map((event) => ({
        ...event,
        start: new Date(event.dateStart),
        end: new Date(event.dateEnd),
        title: event.title,
        description: event.description,
        allDay: event.allDay || false,
      }));

      setEvents(formattedEvents);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erro ao carregar eventos.",
        showConfirmButton: true,
      });
    }
  };

  const getGroup = async () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getAllGroups({ active: 1 }).then((res) => {
        Swal.close();
        return res;
      });
      Array.isArray(response.data.resultSet ?? []);
      setGroupList([...response.data.resultSet] ?? []);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar a lista de grupos:", error);
    }
  };

  const handleShortcutList = async () => {
    try {
      const response = await getRegisterShortcutMessages();
      const data = response.data.resultSet ?? [];

      if (data.length > 0) {
        Swal.close();
        setShortcutList(data);
      } else {
        Swal.close();
        setShortcutList([]);
      }
    } catch (err) {
      Swal.close();
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Erro ao converter arquivo para base64:", error);
        resolve(null);
      };
    });
  };

  const handleUpload = async (ev) => {
    if (ev.target.name === "img") {
      setImagePreview("");
      const file = ev.target.files[0];
      if (file) {
        getBase64(file).then((e) => {
          setImagePreview(e);
        });
      }
      ev.target.value = "";
    }
  };


  const handleIconClick = () => {
    fileInputRef.current.click();
    setImagePreview("")
  };
  const handleToggleTooltip = (id) => {
    setTooltipGroupOpen((prevTooltipOpen) => ({
      ...prevTooltipOpen,
      [id]: !prevTooltipOpen[id],
    }));
  };

  const updateVisibleBadgeCount = () => {
    if (window.innerWidth <= 325) {
      setVisibleBadgeCount(0);
      return;
    }

    if (window.innerWidth <= 470) {
      setVisibleBadgeCount(1);
      return;
    }

    if (window.innerWidth <= 854) {
      setVisibleBadgeCount(2);
      return;
    }

    if (window.innerWidth <= 991) {
      setVisibleBadgeCount(3);
      return;
    }

    if (window.innerWidth <= 1407) {
      setVisibleBadgeCount(4);
      return;
    }

    if (window.innerWidth <= 1580) {
      setVisibleBadgeCount(5);
      return;
    }

    if (window.innerWidth <= 1650) {
      setVisibleBadgeCount(6);
      return;
    }

    if (window.innerWidth <= 1669) {
      setVisibleBadgeCount(8);
      return;
    }

    if (window.innerWidth >= 1880) {
      setVisibleBadgeCount(10);
      return;
    }

    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const badgeElements = Array.from(
        containerRef.current.querySelectorAll(".badge")
      );
      let totalWidth = 0;
      let visibleCount = 0;

      badgeElements.forEach((badgeElement) => {
        const badgeWidth =
          badgeElement.offsetWidth +
          parseInt(window.getComputedStyle(badgeElement).marginRight, 10);
        totalWidth += badgeWidth;
      });

      let accumulatedWidth = 0;
      for (let i = 0; i < badgeElements.length; i++) {
        const badgeElement = badgeElements[i];
        const badgeWidth =
          badgeElement.offsetWidth +
          parseInt(window.getComputedStyle(badgeElement).marginRight, 10);
        accumulatedWidth += badgeWidth;

        if (accumulatedWidth <= containerWidth) {
          visibleCount = i + 1;
        } else {
          break;
        }
      }
      setVisibleBadgeCount(Math.max(visibleCount, 0));
    }
  };

  const debouncedUpdateVisibleBadgeCount = debounce(
    updateVisibleBadgeCount,
    100
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateVisibleBadgeCount();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      debouncedUpdateVisibleBadgeCount.cancel();
    };
  }, [contacts]);
  
      useEffect(() => {
        const handleResize = () => {
          updateVisibleBadgeCount();
        };
    
        window.addEventListener("resize", handleResize);
        updateVisibleBadgeCount();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

  const playAlertSound = () => {
    if (soundPlaying) return;

    const sound = audioRef.current;
    sound.loop = false;
    sound.muted = true;

    sound.play().then(() => {
      setTimeout(() => {
        sound.muted = false;
      }, 100);
      setSoundPlaying(true);

      setTimeout(() => {
        sound.pause();
        sound.currentTime = 0;
        setSoundPlaying(false);
      }, 6000);
    }).catch((error) => {
      console.error("Erro ao tocar o som:", error);
    });
  };

  const checkUnattendedClients = async () => {
    try {
      const response = await unattendedCustomers();

      if (response?.data.resultSet > 0 && !soundPlaying) {
        playAlertSound();
      }

      if (response?.data.resultSet === 0 && soundPlaying) {
        const sound = audioRef.current;
        sound.pause();
        sound.currentTime = 0;
        sound.loop = false;
        setSoundPlaying(false);
      }
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response ? "Ocorreu um erro inesperado" : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    checkUnattendedClients();

    const interval = setInterval(checkUnattendedClients, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleEscKeyPress = (e) => {
      if (e.keyCode === 27) {
        handleCloseConversation();
      }
    };

    document.addEventListener("keydown", handleEscKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  useEffect(() => {
      // socket.current = io("ws://localhost:3000/", {
     socket.current = io("wss://marketplace.izichat.com.br/", {
      transports: ["websocket"],
      reconnectionDelay: 10000,
      auth: {
        authorization: localStorage.getItem("token_izichat_xpto"),
      },
    });
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    socket.current.on("chat", handleChat);
    socket.current.on("error", (err) => {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: err.message,
      });
    });
    return () => {
      socket.current.off("chat", handleChat);
    };
  }, [messages, soundNotification]);

  useEffect(() => {
    scrollToBottom();
    if (contacts.length <= 0) {
      handleListContact({
        limit: limit,
        previousLimit: previousLimit,
      });
    }

    const handleResize = () => {
      setIsMdScreen(window.innerWidth <= 991);
      if (!showSelectedContact && window.innerWidth <= 991) {
        setShowContact(true);
        setShowChat(false);
      }

      if (showSelectedContact && window.innerWidth <= 991) {
        setShowChat(true);
        setShowContact(false);
      }

      if (window.innerWidth > 991) {
        setShowChat(true);
        setShowContact(true);
      }
    };
    window.addEventListener("resize", handleResize);
  }, [window.innerWidth <= 991]);

  useEffect(() => {
    getAllUsers();
    handleShowInstance();
    getGroup();
    handleGetConfig();
  }, []);

  useEffect(() => {
    if (instances?.length > 0) {
      getCountChats();
    }
  }, [instances]);

  useEffect(() => {
    getGroup();
  }, [showModalRegisterGroup]);

  useEffect(() => {
    const getClientGroups = async () => {
      Swal.fire({
        title: "Carregando",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => Swal.showLoading(),
      });

      try {
        const { data } = await getGroupsByClientId(messages.client.clientId);
        Swal.close();
        const formattedGroups =
          data.resultSet?.map((group) => ({
            value: parseInt(group.id),
            label: group.name,
          })) ?? [];
        setSelectedGroup(formattedGroups);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Ocorreu um erro ao buscar os grupos. Tente novamente mais tarde.",
        });
      }
    };

    if (showModalGroup) {
      getClientGroups();
    }
  }, [showModalGroup, messages.client.clientId, getGroupsByClientId]);

  return (
    <>
      <div className="row d-flex align-items-stretch justify-content-stretch">
        {showContact && (
          <ChatContactComponent
            contacts={contacts}
            handleGetMessagesClient={handleGetMessagesClient}
            isMdScreen={isMdScreen}
            contactSelected={selectedItemIndex}
            handleSelectItem={handleIndexItem}
            handleListContact={(filter) => handleListContact(filter)}
            toggleModal={() => setModalNewMessage(!modalNewMessage)}
            handleCloseConversation={handleCloseConversation}
            setSelectedInstanceId={(instance) => {
              setSelectedInstanceId(instance);
            }}
            selectedInstanceId={selectedInstanceId}
            filter={filter}
            setFilter={(filter) => {
              setFilter(filter);
            }}
            showBtnMoreContacts={showBtnMoreContacts}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            limit={limit}
            previousLimit={previousLimit}
            setPreviousLimit={setPreviousLimit}
            tabNavSelected={tabNavSelected}
            setTabNavSelected={setTabNavSelected}
            numberChatService={numberChatService}
            getCountChats={getCountChats}
            user={user}
            setOrigimButtonSelected={setOrigimButtonSelected}
            origimButtonSelected={origimButtonSelected}
            setReadMessages={setReadMessages}
            handleReadMessages={handleReadMessages}
          />
        )}

        {(showChat || !isMdScreen) && (
          <div className="d-flex align-content-stretch col-12 col-lg-7 col-xl-8 mt-3 mt-lg-0">
            {showContactDetails && (
              <div
                ref={contactDetailsRef}
                className="col-12 col-lg-9 col-xl-8 position-absolute"
                style={{ right: 0, zIndex: 200, height: "100%" }}
              >
                <CardDiv className="card rounded-0 h-100">
                  <CardHeader className="d-flex align-items-center bg-white border-0">
                    <Button
                      type="button"
                      aria-label="Close"
                      onClick={() => {
                        handleOpenContactDetails();
                      }}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                        fontSize: "1.5rem",
                      }}
                      color="light"
                      className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center border-0"
                    >
                      <i className="eva eva-close-outline"></i>
                    </Button>
                    <h5 className="mb-0 headline-3">Dados do Contato</h5>
                  </CardHeader>
                  <CardBody className="d-flex align-items-center flex-column">
                    <div className="mr-3">
                      <AvatarDiv>{messages.client.name?.slice(0, 2)}</AvatarDiv>
                    </div>
                    {editMode ? (
                      <InputGroup className="mb-3 w-50">
                        <Input
                          type="text"
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleConfirmEdit();
                            }
                          }}
                        />
                      </InputGroup>
                    ) : (
                      <div
                        className="text-center"
                        style={{ fontSize: "1.2rem", cursor: "pointer" }}
                        onClick={handleEditClick}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleEditClick();
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {messages.client.name}
                      </div>
                    )}
                    <p style={{ fontSize: "1rem", color: "#A1A5B7" }}>
                      {phoneMask(messages.client.phone)}
                    </p>
                    {messages?.client?.userIdChat && (
                      <p>
                        Atendente:{" "}
                        <strong>{messages.client.attendentName}</strong>
                      </p>
                    )}
                    <div
                      className="d-flex ml-lg-3 align-items-center my-md-0 my-1 flex-wrap mt-5"
                      style={{
                        gap: "3px",
                        position: "relative",
                      }}
                    >
                      {messages.groups.map((group, key) => (
                        <div key={key}>
                          <Badge color="primary" className="badge-group">
                            <span
                              style={{
                                fontSize: "11px",
                                maxWidth: "100px",
                                lineHeight: "11px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {group.name}
                            </span>
                          </Badge>
                        </div>
                      ))}
                    </div>
                    <Card className="border-0 mt-5 w-100">
                      <CardBody>
                        <Nav style={{ height: "41px" }} tabs>
                          <NavItem>
                            <NavLink
                              className={tabSelected === 1 && "active"}
                              onClick={() => {
                                setTabSelected(1);
                              }}
                            >
                              Observações
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </CardBody>
                      <TabContent
                        style={{ maxHeight: "350px", overflow: "auto" }}
                      >
                        <FormGroup className="w-100 px-3">
                          <Input
                            type="textarea"
                            className="mb-3"
                            style={{
                              maxHeight: "90px",
                              overflow: "auto",
                              resize: "none",
                            }}
                            onChange={(e) => setObservation(e.target.value)}
                            value={observation}
                          />
                          <Button
                            className="mt-3 w-100 bg-success text-white"
                            onClick={() => {
                              handleSaveObservation(messages.client.clientId);
                            }}
                          >
                            Salvar
                          </Button>
                        </FormGroup>
                        <Card className="border-0">
                          <CardHeader className="border-0 bg-white">
                            <Row>
                              <Col md="9" xl="9">
                                <div className="">
                                  <div className="headline-3">
                                    Lista de observações{" "}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <div className="widget-table-overflow">
                              {list.length > 0 ? (
                                <>
                                  {list.map((observation, key) => (
                                    <div
                                      style={{
                                        padding: "5px 20px 20px",
                                        background: "#f5f5f5",
                                        borderRadius: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p style={{ fontSize: "0.8rem" }}>
                                          <strong>
                                            {observation.createdAt.slice(0, 10)}
                                          </strong>
                                        </p>
                                        <button
                                          className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center border-0"
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            fontSize: "1.2rem",
                                          }}
                                          onClick={() =>
                                            handleDelete(
                                              observation.id,
                                              observation.clientId
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-trash-can"></i>
                                        </button>
                                      </div>
                                      <p style={{ whiteSpace: "pre-line" }}>
                                        {observation.text}
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="alert alert-secondary">
                                  <h4 className="text-dark text-center">
                                    Não possuem registros no momento.
                                  </h4>
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </TabContent>
                    </Card>
                  </CardBody>
                </CardDiv>
              </div>
            )}
            <CardDiv className="p-0 position-relative h-100">
              {!messages.client.name ? (
                <NotFound>
                  <img src={NotFoundIMG} alt="select some conversation" />
                </NotFound>
              ) : (
                <>
                  <Header>
                    <section className="w-100 d-flex position-relative align-items-center">
                      <SectionHeader>
                        <div
                          className="d-flex"
                          onClick={() => {
                            handleOpenContactDetails();
                            getObservations(messages.client.clientId);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              handleOpenContactDetails();
                              getObservations(messages.client.clientId);
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="mr-3">
                            <Avatar>{messages.client.name?.slice(0, 2)}</Avatar>
                          </div>
                          <div>
                            <p className="text-success fw-bold">
                              {messages.client.name}
                            </p>
                            <p className="phone">
                              {phoneMask(messages.client.phone)}
                            </p>
                            {messages?.client?.userIdChat && (
                              <p
                                className="text-primary"
                                style={{ fontSize: "10px" }}
                              >
                                <i className="fas fa-headset"></i>{" "}
                                {messages.client.attendentName}
                              </p>
                            )}
                          <div  
                            className="d-flex align-items-center my-md-0 my-1"
                            style={{
                              gap: "5px",
                              position: "relative",
                            }}
                            ref={containerRef}
                          >
                            {Array.isArray(messages.groups) &&
                              messages.groups.length > 0 && (
                                
                                <>
                                  {messages.groups
                                    .slice(0, visibleBadgeCount)
                                    .map((group, idx) => {
                                      const badgeId = `badge-${messages.clientPhoneNumberId}-${idx}`;
                                      const tooltipId = `tooltip-${messages.clientPhoneNumberId}-${idx}`;

                                      return (
                                        <React.Fragment key={badgeId}>
                                          <Badge
                                            id={badgeId}
                                            color="primary"
                                            className="badge-group"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              handleToggleTooltip(tooltipId)
                                            }
                                          >
                                            <span
                                            style={{
                                              fontSize: "9px",
                                              minWidth: "fit-content",
                                              maxWidth: "100%",
                                              lineHeight: "12px",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              display: "inline-flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "2px 5px",
                                            }}
                                          >
                                            {group.name}
                                          </span>
                                          </Badge>
                                          <Tooltip
                                            id={tooltipId}
                                            placement="top"
                                            isOpen={tooltipGroupOpen[tooltipId]}
                                            target={badgeId}
                                            toggle={() =>
                                              handleToggleTooltip(tooltipId)
                                            }
                                            style={{
                                              backgroundColor: "#FFF",
                                              border: "1px solid #C7D0D9",
                                              color: "#000",
                                              borderRadius: "5px",
                                              padding: "10px",
                                              whiteSpace: "pre-wrap",
                                              textAlign: "left",
                                            }}
                                          >
                                            {group.name}
                                          </Tooltip>
                                        </React.Fragment>
                                      );
                                    })}

                                  {messages.groups.length >
                                    visibleBadgeCount && (
                                    <UncontrolledDropdown
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <DropdownToggle
                                        color="light"
                                        id="dropdown-basic"
                                        className="d-flex align-items-center justify-content-center badge bg-secondary mb-lg-0 mb-1"
                                        style={{
                                          fontSize: "11px",
                                          maxHeight: "20px",
                                          cursor: "pointer",
                                          width: "auto",
                                          padding: "11px 15px",
                                        }}
                                      >
                                        +
                                        {messages.groups.length -
                                          visibleBadgeCount}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {messages.groups
                                          .slice(visibleBadgeCount)
                                          .map((group) => (
                                            <DropdownItem
                                              key={group.id}
                                              className="p-2"
                                            >
                                              {group.name}
                                            </DropdownItem>
                                          ))}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  )}
                                </>
                              )}
                          </div>
                          </div>
                        </div>
                        {instances.length > 1 && (
                          <DivSelect>
                            <Select
                              options={instances}
                              isLoading={instances.length <= 0}
                              value={selectedInstance}
                              placeholder="Selecione..."
                              onChange={(selectedOption) => {
                                setSelectedInstance(selectedOption);
                                setShouldUpdateInstances(false);
                              }}
                            />
                          </DivSelect>
                        )}
                      </SectionHeader>
                      <DivButton className="flex-wrap">
                        {!messages.client.userIdChat ? (
                          <Button
                            className="rounded-circle p-3 d-flex"
                            color="light"
                            onClick={() => {
                              handleConversation(messages.client, 1);
                            }}
                          >
                            <i className="fas fa-phone text-success"></i>
                          </Button>
                        ) : null}
                        {messages.client.userIdChat &&
                          messages.client.userIdChat == user.userId && (
                            <Button
                              className="rounded-circle p-3 d-flex"
                              color="light"
                              onClick={() => {
                                handleConversation(messages.client, 0);
                              }}
                            >
                              <i className="fas fa-phone-slash text-danger"></i>
                            </Button>
                          )}
                      </DivButton>
                      <Dropdown
                        style={{
                          position: "absolute",
                          right: "0",
                        }}
                        isOpen={showDropdown}
                        toggle={() => handleToggleDropdown()}
                      >
                        <DropdownToggle
                          style={{ width: "50px", height: "50px" }}
                          color="light"
                          className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center"
                        >
                          <i className="fa fa-ellipsis-v text-success" />
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            margin: "5px 7rem 0 0",
                          }}
                        >
                          <DropdownItem onClick={handleCloseConversation}>
                            <i className="fa-solid fa-comment-slash mr-2"></i>
                            Fechar Conversa
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              searchModalMessage();
                            }}
                          >
                            <i className="fa-solid fa-magnifying-glass mr-2"></i>
                            Pesquisar
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              toggleModal();
                            }}
                          >
                            <i className="fa-solid fa-calendar-days mr-2"></i>
                            Agendar Evento
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              handleOpenContactDetails();
                              getObservations(messages.client.clientId);
                            }}
                          >
                            <i className="fa-solid fa-address-book mr-2"></i>
                            Dados do Contato
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              toggleModalGroup();
                              getGroup();
                            }}
                          >
                            <i className="fa-solid fa-people-group mr-2"></i>
                            Vincular Grupo
                          </DropdownItem>
                          {messages.client.userIdChat > 0 &&
                            user.userId == messages.client.userIdChat && (
                              <DropdownItem
                                onClick={toggleTransferConversationModal}
                              >
                                <i className="fas fa-exchange-alt mr-2"></i>
                                Transferir Conversa
                              </DropdownItem>
                            )}
                        </DropdownMenu>
                      </Dropdown>
                    </section>
                  </Header>
                  <Body>
                    <ContainerMessage id="message-chat">
                      {messages && messages.list.length > 0 ? (
                        messages.list.map((conversation, key) => (
                          <ChatBubble
                            key={key}
                            type={conversation.fromMe <= 0 ? "sent" : null}
                          >
                            {conversation.type === "audioMessage" && (
                              <audio className="chat-audio" controls>
                                <source
                                  src={conversation.url}
                                  type="audio/ogg"
                                />
                                <track kind="captions" />
                              </audio>
                            )}
                            {conversation.type === "videoMessage" && (
                              <video controls className="rounded chat-video">
                                <source
                                  src={conversation.url}
                                  type="video/mp4"
                                />
                                <track kind="captions" />
                              </video>
                            )}
                            {conversation.type === "imageMessage" && (
                              <a href={conversation.url} target="_blank">
                                <img
                                  src={conversation.url}
                                  alt={conversation.whatsappId}
                                  className="rounded chat-image"
                                />
                              </a>
                            )}
                            <span className="span">
                              {conversation.createdAt}
                              {conversation.isRead > 0 ? (
                                <i className="icon-visualized text-info ml-1">✔✔</i>
                              ) : (
                                <i className="icon-not-visualized ml-1">✔</i>
                              )}
                            </span>
                            <p>
                              {parse(
                                FormatMessageWhatsApp(conversation.message)
                              )}
                            </p>
                            <Badge className="bg-info text-white mt-1">
                              {conversation.instanceName}
                            </Badge>
                          </ChatBubble>
                        ))
                      ) : (
                        <></>
                      )}
                      <div ref={theEnd} />
                    </ContainerMessage>
                    <div>
                      {messages.client.userIdChat && user.userId ? (
                        <Footer className="flex-column align-items-start">
                          {isFilterMessage && (
                            <ContainerFilter>
                              <Alert
                                className="bg-transparent text-primary border-0 w-100 text-center position-absolute mb-0"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setMessages((prevMessages) => ({
                                    ...prevMessages,
                                    list: messages.list || [],
                                  }));
                                  handleGetMessagesClient(
                                    selectedItemIndex,
                                    null,
                                    selectedContact
                                  );
                                  setIsFilterMessage(false);
                                }}
                              >
                                <i className="fa-solid fa-rotate mr-1"></i>
                                Limpar filtro
                              </Alert>
                            </ContainerFilter>
                          )}
                          <ContainerFooter>
                            {triggerShortcut && (
                              <ScrollShortcutMessage>
                                {shortcutList.map((ShortcutMessage) => (
                                  <div key={ShortcutMessage.id}>
                                    <Badge
                                      onClick={() => {
                                        setShortcutMessage(ShortcutMessage.message);
                                        setTriggerShortcut(false);
                                      }}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        color: "inherit",
                                        textAlign: "left",
                                        padding: 0,
                                        cursor: "pointer",
                                        fontSize: "inherit",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        width: "100%",
                                      }}
                                    >
                                      <ShortcutContainer>
                                        <ShortcutTitleLabel className="pt-2 pb-2">
                                          {ShortcutMessage.title}
                                        </ShortcutTitleLabel>
                                        <ShortcutMessageLabel>
                                          {ShortcutMessage.message}
                                        </ShortcutMessageLabel>
                                      </ShortcutContainer>
                                    </Badge>
                                  </div>
                                ))}
                              </ScrollShortcutMessage>
                            )}
                            {messages.client.userIdChat == user.userId ? (
                              <>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen}
                                  target="label"
                                  toggle={toggleTooltip}
                                  style={{
                                    backgroundColor: "#FFF",
                                    border: "1px solid #C7D0D9",
                                    color: "#000",
                                    fontWeight: "600",
                                    borderRadius: "5px",
                                    width: "15rem",
                                    padding: "10px",
                                    whiteSpace: "pre-wrap",
                                    textAlign: "left",
                                  }}
                                >
                                  Se ativado, a mensagem será enviada com o nome
                                  do usuário; se desativado, o nome não será
                                  enviado.
                                </Tooltip>
                                <div className="d-flex align-items-center">
                                  <FormGroup>
                                    <Label className="d-flex mb-0" id="label">
                                      <Switch
                                        checked={signatureEnabled}
                                        onChange={(checked) => setSignatureEnabled(checked)}
                                        offColor="#C7D0D9"
                                        onColor="#009582"
                                        handleDiameter={15}
                                        height={10}
                                        width={40}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                      />
                                      <span className="ml-2" style={{ fontSize: "13px", fontWeight: "600" }}>
                                        Assinatura
                                      </span>
                                    </Label>
                                  </FormGroup>
                                  <InputGroup className="ml-2 mb-3">
                                    <button
                                      type="button"
                                      onClick={handleIconClick}
                                      className="btn btn-light rounded-circle p-3 d-flex"
                                    >
                                      <FaPaperclip size={15} />
                                    </button>
                                    <input
                                      ref={fileInputRef}
                                      name="img"
                                      onChange={(e) => {
                                        handleUpload(e);
                                        setTriggerPreview(true);
                                        setImagePreview("");
                                      }}
                                      className="d-none"
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                    />
                                  </InputGroup>
                                </div>
                                <PreviewBody>
                                  {triggerPreview && (
                                    <img src={imagePreview} />
                                  )}
                                </PreviewBody>
                                <FormGroup className="w-100 group">
                                  <InputGroup>
                                    <ReactTextareaAutocomplete
                                      id="messageTextarea"
                                      className="form-control w-100 bg-white"
                                      style={{ height: 100, zIndex: 999 }}
                                      placeholder="Digite uma mensagem"
                                      loadingComponent={() => <span>Loading</span>}
                                      value={shortcutMessage}
                                      onChange={(e) => setShortcutMessage(e.target.value)}
                                      trigger={{
                                        '/': {
                                          dataProvider: () => shortcutList,
                                          component: ({ title, message }) => (
                                            <div>
                                              <strong>{title}</strong>
                                              <p>{message}</p>
                                            </div>
                                          ),
                                          output: (item) => item.message,
                                        },
                                      }}
                                      onKeyDown={(e) => {
                                        const cursorPosition = e.target.selectionStart;
                                        const textBeforeCursor = e.target.value.slice(0, cursorPosition);
                                        if (e.key === '/' && (textBeforeCursor === "" || textBeforeCursor.endsWith(" "))) {
                                          setTriggerShortcut(true);
                                        } else {
                                          setTriggerShortcut(false);
                                        }
                                        if (e.key !== "Enter") return;

                                        if (selectedInstance && selectedInstance.label) {
                                          handleSendMessage({
                                            instance: selectedInstance,
                                            clientPhoneNumberId: messages.client.clientPhoneNumberId,
                                            message: e.target.value,
                                            img: imagePreview
                                          });
                                          setShortcutMessage("");
                                          setTriggerPreview(false);
                                          setImagePreview("");
                                        } else {
                                          Swal.fire({
                                            icon: "error",
                                            title: "Instância não selecionada",
                                            text: "Selecione uma instância para enviar a mensagem.",
                                          });
                                        }
                                        e.preventDefault();
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </>
                            ) : (
                              <Alert color="secondary">
                                <p
                                  className="text-success text-center"
                                  style={{ fontSize: "18px" }}
                                >
                                  Essa conversa pertence a(o) atendente{" "}
                                  {messages.client.attendentName}.
                                </p>
                              </Alert>
                            )}
                          </ContainerFooter>
                        </Footer>
                      ) : (
                        <Footer className="flex-column align-items-center">
                          {isFilterMessage && (
                            <ContainerFilter>
                              <Alert
                                className="bg-transparent text-primary border-0 w-100 text-center position-absolute mb-0"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setMessages((prevMessages) => ({
                                    ...prevMessages,
                                    list: messages.list || [],
                                  }));
                                  handleGetMessagesClient(
                                    selectedItemIndex,
                                    null,
                                    selectedContact
                                  );
                                  setIsFilterMessage(false);
                                }}
                              >
                                <i className="fa-solid fa-rotate mr-1"></i>
                                Limpar filtro
                              </Alert>
                            </ContainerFilter>
                          )}
                          <ContainerFooter>
                            <Alert color="secondary">
                              <p
                                className="text-success text-center"
                                style={{ fontSize: "18px" }}
                              >
                                {messages.client.userIdChat == null ? (
                                  <p>
                                    Pressione o botão
                                    <i className="fa-solid fa-phone mr-2 ml-2"></i>
                                    para iniciar.
                                  </p>
                                ) : (
                                  `Essa conversa pertence a(o) atendente ${messages.client.attendentName}.`
                                )}
                              </p>
                            </Alert>
                          </ContainerFooter>
                        </Footer>
                      )}
                    </div>
                  </Body>
                </>
              )}
              <Modal
                isOpen={showModalGroup}
                toggle={toggleModalGroup}
                className="modal-lg modal-dialog-centered"
              >
                <ModalHeader toggle={toggleModalGroup}>
                  Vincular Grupo
                </ModalHeader>
                <ModalBody>
                  <Card className="shadow-none border-0">
                    <Card className="shadow-none border">
                      <CardBody>
                        <FormGroup className="d-flex flex-column">
                          <Label for="groupSelect">
                            Cadastre um novo Grupo
                          </Label>
                          <Button
                            color="success"
                            onClick={toggleModalRegisterGroup}
                            style={{ maxWidth: "150px" }}
                          >
                            Cadastrar
                          </Button>
                        </FormGroup>
                      </CardBody>
                    </Card>

                    <Card className="shadow-none border mt-2">
                      <CardBody>
                        <FormGroup>
                          <Label for="groupSelect">
                            Selecione um ou mais Grupos
                          </Label>
                          <Select
                            isMulti
                            id="groupSelect"
                            options={groupList.map((group) => ({
                              value: parseInt(group.id),
                              label: group.name,
                            }))}
                            onChange={(selectedOption) =>
                              setSelectedGroup(selectedOption)
                            }
                            value={selectedGroup}
                            placeholder="Selecione..."
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Card>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModalGroup}>
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleAddGroup(messages.client.clientId, messages.client)}
                  >
                    Salvar
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                isOpen={modalSearchChat}
                toggle={searchModalMessage}
                className="modal-lg modal-dialog-centered"
              >
                <ModalHeader toggle={searchModalMessage}>Pesquisar</ModalHeader>
                <ModalBody>
                  <Card className="shadow-none border-0">
                    <Card className="shadow-none border">
                      <CardBody>
                        <FormGroup className="d-flex flex-column">
                          <InputGroup>
                            <input
                              id="filterMessage"
                              type="text"
                              placeholder="Pesquisar..."
                              className="form-control form-control-md"
                              value={filterMessage}
                              onChange={(ev) =>
                                setFilterMessage(ev.target.value)
                              }
                              onKeyDown={(ev) => {
                                if (ev.key === "Enter") {
                                  handleGetSearchMessagesClient(filterMessage);
                                }
                              }}
                              title="Digite o número, nome ou mensagem do contato para realizar a pesquisa."
                            />
                            <InputGroupAddon addonType="append">
                              <Button
                                className="py-0 px-2 bg-white border-left-0"
                                onClick={() =>
                                  handleGetSearchMessagesClient(filterMessage)
                                }
                              >
                                <i className="fa fa-search text-secondary"></i>
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                        {isAlertSearch && (
                          <Alert color="danger" className="text-danger">
                            Essa mensagem não foi encontrada
                          </Alert>
                        )}
                      </CardBody>
                    </Card>
                  </Card>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={searchModalMessage}>
                    Cancelar
                  </Button>
                </ModalFooter>
              </Modal>
            </CardDiv>
            <Modal
              isOpen={showTransferModal}
              toggle={toggleTransferConversationModal}
              className="modal-dialog-centered"
            >
              <ModalHeader toggle={toggleTransferConversationModal}>
                Transferir Conversa
              </ModalHeader>
              <ModalBody>
                <Alert color="info" className="text-info">
                  Selecione um operador para transferir esta conversa
                </Alert>
                <FormGroup className="mt-3">
                  <Label for="operatorSelect">Operadores Ativos</Label>
                  <Select
                    id="operatorSelect"
                    options={userAttendantList
                      .filter((operator) => operator.id != user.userId)
                      .map((operator) => ({
                        value: operator.id,
                        label: operator.name,
                      }))}
                    onChange={(selectedOption) =>
                      setSelectedOperator(selectedOption)
                    }
                    value={selectedOperator}
                    placeholder="Selecione o operador..."
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={toggleTransferConversationModal}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    handleConfirmTransfer(messages.client.clientId)
                  }
                >
                  Transferir
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}

        <ChatNewMessageComponent
          showModal={modalNewMessage}
          toggleModal={setModalNewMessage}
          sendMessage={handleSendMessage}
          handleListContact={handleListContact}
          handleGetMessagesClient={handleGetMessagesClient}
        />

        <EventModal
          modal={modal}
          toggleModal={toggleModal}
          handleList={handleList}
        />

        <RegisterGroup
          isOpen={showModalRegisterGroup}
          toggleModal={toggleModalRegisterGroup}
          execute={() => {
            getGroup();
          }}
        />
      </div>
    </>
  );
};

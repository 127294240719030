import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import s from "./Breadcrumbs.module.scss";
import { getDaysToExpire } from "../../services/License/index.js";
import Menu from "../Layout/assets/menu.png";
import Swal from "sweetalert2";

const Breadcrumbs = (props) => {
  const history = useHistory();
  const [daysToExpire, setDaysToExpire] = useState(0);
  const [isChatFullscreen, setIsChatFullscreen] = useState(false);
  const [isScreenLarge, setIsScreenLarge] = useState(true);
  const [fullScreen, setFullScreen] = useState(true);
  const [isRowVisible, setIsRowVisible] = useState(true);
  const [isZoomActive, setIsZoomActive] = useState(false);

  const checkScreenSize = () => {
    setIsScreenLarge(window.innerWidth > 991);
  };

  const adjustZoom = () => {
    if (isZoomActive && window.innerWidth >= 991 && window.innerWidth <= 1500 && window.innerHeight <= 800) {
      document.body.style.zoom = "0.75";
    } else {
      document.body.style.zoom = "1";
    }
  };

  const toggleChatFullscreen = () => {
    const newFullscreenState = !isChatFullscreen;
    setIsChatFullscreen(newFullscreenState);
    setIsRowVisible(!newFullscreenState);

    window.scrollTo(0, 0);

    if (newFullscreenState) {
      document.body.style.overflow = "hidden";
      props.setWrapClass(s.customWrap);
      props.setButtonClass("d-none");
      setFullScreen(false);
      setIsZoomActive(true);
    } else {
      document.body.style.overflow = "auto";
      props.setWrapClass(s.wrap);
      props.setButtonClass("classMenu");
      props.setButtonImage(Menu);
      setFullScreen(true);
      setIsZoomActive(false);
    }


    localStorage.setItem("chatFullscreen", newFullscreenState ? "true" : "false");
    window.dispatchEvent(new Event("chatFullscreenChange"));
  };

  const renderBreadcrumbs = () => {
    let url = props.url;
    let route = props.url.split('/').slice(1).map(route =>
      route.split('-').map(word => word.length < 3 ? word.toUpperCase() : word[0].toUpperCase() + word.slice(1)).join(' ')
    );
    const length = route.length;

    return route.map((item, index) => {
      let middlewareUrl = "/" + url.split("/").slice(1, index + 2).join("/");

      return (
        <BreadcrumbItem key={uuidv4()}>
          {length === index + 1
            ? item
            : <Link to={middlewareUrl}>{item}</Link>
          }
        </BreadcrumbItem>
      );
    });
  };

  const handleDaysToExpirePlan = useCallback(async () => {
    try {
      const response = await getDaysToExpire();
      const fetchedDays = response.data.resultSet;
      setDaysToExpire(fetchedDays);

      setTimeout(() => {
        if (fetchedDays <= 0) {
          history.push("/admin/finance");
        }
      }, 1000);

    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  }, []);

  useEffect(() => {
    handleDaysToExpirePlan();
  }, [handleDaysToExpirePlan, props.url]);

  useEffect(() => {
    localStorage.removeItem("chatFullscreen");
    setIsChatFullscreen(false);
    setIsRowVisible(true);
    document.body.style.overflow = "auto";
    props.setWrapClass(s.wrap);
    props.setButtonClass("classMenu");
    props.setButtonImage(Menu);
    setIsZoomActive(false);
  }, [props.url]);

  useEffect(() => {
    window.addEventListener("resize", checkScreenSize);
    checkScreenSize();

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    if (!isScreenLarge) {
      if (isChatFullscreen) {
        setIsChatFullscreen(false);
        setIsRowVisible(true);
        document.body.style.overflow = "auto";
        props.setWrapClass(s.wrap);
        props.setButtonClass("classMenu");
        props.setButtonImage(Menu);
        localStorage.removeItem("chatFullscreen");
        setIsZoomActive(false);
      }
    }
  }, [isScreenLarge, isChatFullscreen, props]);

  useEffect(() => {
    const handleResize = () => {
      if (isZoomActive) {
        adjustZoom();
      }
      if (window.devicePixelRatio > 1) {
        document.body.style.overflow = "auto";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overflow = "auto"; 
    };
  }, [isZoomActive]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" && isChatFullscreen) {
        toggleChatFullscreen();
      }
    };

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isChatFullscreen, toggleChatFullscreen]);

  let routeArr = props.url.split('/');
  let title = routeArr[routeArr.length - 1];
  let breadcrumbTitle = title[0].toUpperCase() + title.slice(1);

  return (
    <div className={s.breadcrumbs}>
      <div className="headline-2 d-flex justify-content-between w-100">
        {isRowVisible && (
          <div className="row w-100">
            <div className="col-md-8">
              {breadcrumbTitle}
              {breadcrumbTitle !== "Dashboard" && (
                <Breadcrumb tag="nav" listTag="div">
                  {renderBreadcrumbs()}
                </Breadcrumb>
              )}
            </div>

            <div className="col-md-4 d-flex flex-column align-items-end px-0">
              <div className="text-primary text-right">
                {daysToExpire <= 0 ? (
                  <span className="badge badge-warning">
                    <p className="text-white">Ativar registro</p>
                  </span>
                ) : (
                  <p>
                    Dias restantes:{" "}
                    <span className="badge badge-success">
                      {daysToExpire}
                    </span>
                  </p>
                )}
              </div>

              {breadcrumbTitle === "Chat" && isScreenLarge && (
                <Button
                  onClick={toggleChatFullscreen}
                  style={{ fontSize: "2rem" }}
                  className="bg-transparent px-2 py-0 border-0 mt-2"
                >
                  <i className={`fa-solid ${isChatFullscreen ? "fa-minimize" : "fa-expand"}`}></i>
                </Button>

              )}
            </div>
          </div>
        )}
        {breadcrumbTitle === "Chat" && isScreenLarge && !isRowVisible && (
          <Button
            onClick={toggleChatFullscreen}
            style={{ fontSize: "2rem" }}
            className="bg-transparent px-2 py-0 border-0 ml-auto"
          >
            <i className={`fa-solid ${isChatFullscreen ? "fa-minimize" : "fa-expand"}`}></i>
          </Button>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;

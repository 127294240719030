import styled from "styled-components";

export const AvatarDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 50%;
  background: #00958242;
  color: #009582;
  width: 90px;
  height: 90px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

export const ScrollShortcutMessage = styled.div`
  max-height: 100px;
  overflow: auto;
  width: 100%;

  &::-webkit-scrollbar {
    background-color: inherit;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background: #eff2f5;
    border-radius: 8px;
    border: 4px solid transparent;
    box-sizing: border-box;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d1d5db;
  }

  &:hover {
    pointer-events: auto;
  }

  & > div:nth-child(odd) {
    background-color: #f8f9fa; /* Cor de fundo para listras */
  }

  & > div:hover {
    background-color: #e9ecef;
  }
`;

export const ShortcutTitleLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
`;

export const ShortcutMessageLabel = styled.div`
  color: #a9a9a9;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50rem;
  height: 14px;
`;

export const ShortcutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const PreviewBody = styled.div`
    width: 75%;
    max-width: 300px;
    height: auto;
    max-height: 200px;
    background-color: #4D53E0;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 1.5rem;
    word-wrap: break-word;
    overflow-y: auto;
    gap: 15px;
`;

export const ImagePreview = styled.img`
    width: 100%;
    max-height: 200px;
`;


